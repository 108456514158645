@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@200&family=Bitter:wght@203&display=swap");

$background-dark: #282c34;
$background-body-header: radial-gradient(
  circle at top,
  rgba(0, 24, 47, 1) 0%,
  rgba(7, 18, 29, 1) 6%,
  rgba(40, 44, 52, 1) 100%
);
$background-body: #4b696e;

$text-brand: rgb(245, 238, 238);
$text-container: rgb(156, 199, 190);

$home-background-image: url("./images/pexels-pixabay-87009.jpg");

$text-font-archivo: "Archivo", sans-serif;

.App {
  text-align: center;
}

html,
body,
#app,
#app > div {
  height: 100%;
}

#root {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.preloadCont {
  min-height: 100%;
  background-color: $background-body;
}

#navHomeText {
  color: rgb(209, 209, 209);
  font-size: 24px;
}

#navWebText {
  color: rgb(209, 209, 209);
  font-size: 24px;
}

#navGameText {
  color: rgb(209, 209, 209);
  font-size: 24px;
}

#navContactText {
  color: rgb(209, 209, 209);
  font-size: 24px;
}

#brandText {
  color: $text-brand;
  font-family: $text-font-archivo;
  font-size: 32px;
}

#offcanvasNavbar {
  background-color: $background-dark;
}

#offcanvasNavbarLabel {
  color: $text-brand;
}

#bodyHeaderContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: 400px;
  color: $text-container;
  animation-name: textFly;
  animation-duration: 2s;
  font-family: $text-font-archivo;
}

#home {
  background-image: $home-background-image;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.projectDesc {
  p {
    color: $text-brand;
  }
}

#bodyContainer {
  background-image: $background-body-header;
  height: 100%;
  overflow: hidden;
}

.bodyContent {
  p {
    color: $text-container;
  }
}

#headerText {
  color: $text-brand;
}

#bodyIntroHeader {
  color: $text-brand;
  font-family: $text-font-archivo;
}

#preloadDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10%;
  color: $text-brand;
  font-family: $text-font-archivo;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

@keyframes textFly {
  0% {
    transform: translateX(200%);
  }

  100% {
    transform: translateX(0%);
  }
}
